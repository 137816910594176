import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSession } from "../firebase/UserProvider";
import { firestore } from "../firebase/config";
//import { ProfileImage } from '../ProfileImage';
import { Player, ControlBar } from "video-react";
import Tilt from "react-tilt";


import { ProfileImage } from "../ProfileImage";

//function Designer(props){

const Designer = () => {
  //const Designer = (props) => {

  const params = useParams();

  const name2id = {
    "Andrea-Martinez": "kSWkNFWMPUNZIsrUVX967myz4v52",
    "Joshua-Nolan": "pXZZoRXDA5Zf1qzBnjU5K66LSk03",
    "Daniela-Cardenas" : '5aBQvj1ofOdHYbzTLeRKrhpSYgG3',
    "Regina-Dante": "YeVBTkxPIDdzRzGRhOaOv00CV5d2",
    "Daniela-Diaz": "AiDqGcVSlnWRNkgniuzpqr3jKdq2",

    "Catalina-Bravo": "F9ZmDVC4PxZ7y0GqcOdothZcxNf2",
    "Annette-Martinez": "KqkFMMvgCEdqPNH0ObTvKGk0OwK2",

    "Gino-Castellanos": "xuSUVhBkYMZDZZv9g2XqGnK67oY2",
    "Jeremy-D'Elia": "Uxxs1wq5YuPdRyjDeOTUHRO746F2",
    "Jessica-Love":"62FrttV3D4UGT2ftInNxVC7PcXl1",
    "Dominique-Northecide": "RPMwEkdpYgNyQHGzeIqSLtNVSM42",
    "Diana-Zuniga": "X0ykVJURWHeapJYn61AgMO3OI962",
    "Anika-Pelle": "vtcfFnbTu2SsMzRIgXmDxd6i73u2",
    "Jessica-Reyes": "rGlRahLBAcQnw5ULTPzVxuBTB7o1",

  };
  //const P1 = "null";

  //export default class Designer extends Component {
  //const { user } = useSession();
  //const [userDocument, setUserDocument] = useState(null);

  //const { register, setValue, handleSubmit } = useForm();
  //const [users, setUsers] = useState([]);

  const [formData, setValue] = useState([]);

  useEffect(() => {
    const docRef = firestore.collection("users").doc(name2id[params.name]);
    const unsubscribe = docRef.onSnapshot((doc) => {
      //console.log("Doc: ",doc.data().projects[0].title)
      if (doc.exists) {
        const formData = doc.data(); //doc.map( (d)=> d.data() );
        //const formDataProjects0 = formData.projects[0].title //doc.map( (d)=> d.data() );

        setValue(formData);
      }
    });
    return unsubscribe;
  }, []);

  //const P1 = users[0].eB3N1foSAZXqXbVdrHyxKNynOou1[0].projects[0].title
  //const P1 = users[0].projects[0].title
  //console.log("Users ",users.map((u)=>(u)))
  //console.log("Users: ",users[0]);
  //const P2=users[0];
  //render() {

  //const { register, setValue, handleSubmit } = useForm();
  //const [userDocument, setUserDocument] = useState(null);
  //const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   const docRef = firestore.collection('users').doc(params.id);
  //   const unsubscribe = docRef.onSnapshot((doc) => {
  //     if (doc.exists) {
  //       const documentData = doc.data();
  //       setUserDocument(documentData);
  //       const formData = Object.entries(documentData).map((entry) => ({
  //         [entry[0]]: entry[1],
  //       }));

  //       //setValue(formData);
  //     }
  //   });
  //   return unsubscribe;
  // }, [ params.id]);

  //console.log("NAME: ",parms);
  //const { user } = useSession();
  return (
    <div className="App3">
      <meta charSet="utf-8" />
      <title>Designer</title>
      <meta content="Project 4" property="og:title" />
      <meta content="Project 4" property="twitter:title" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Webflow" name="generator" />
      <link href="../css/normalize.css" rel="stylesheet" type="text/css" />
      <link href="../css/webflow.css" rel="stylesheet" type="text/css" />
      <link
        href="../css/perspectivebfa.webflow.css"
        rel="stylesheet"
        type="text/css"
      />
      {/* [if lt IE 9]><![endif] */}
      <link
        href="../images/favicon.ico"
        rel="shortcut icon"
        type="image/x-icon"
      />
      <link href="../images/webclip.png" rel="apple-touch-icon" />
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        role="banner"
        className="navigation w-nav"
      >
        <div className="navigation-items">
          <a href="../home" className="logo-link w-nav-brand">
            Perspective BFA 2020
          </a>
          <div className="navigation-wrap">
            <nav role="navigation" className="navigation-items w-nav-menu">
              <a href="../acknowledgements" className="navigation-item w-nav-link">
                Acknowledgements
              </a>
            </nav>
            {/*<div className="menu-button w-nav-button">
              <img
                src="../images/menu-icon_1menu-icon.png"
                alt=""
                className="menu-icon"
                width={22}
              />
  </div>*/}

          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="w-layout-grid project-overview-grid">
            {/* */}
            <Tilt
              className="image-5 " 
              id="w-node-b703aaebbc6b-db957e3d"
              options={{ max: 5, scale: 1.0 }}
              style={{ width: 325 }}
            >
              <img src={`../images/pics/${name2id[params.name]}.jpg`}></img>
              
            </Tilt>
            

            <div id="w-node-f2c6de040bbf-db957e3d">
            

              <h1 align="left" className="heading-jumbo">
                {params.name.replace("-", " ")}
              </h1>
              <a href={formData.website}>
                <div className="paragraph-light">

                  {`${formData.website}`.replace("https://", "").replace("/$", "")}{" "}
                  {/*`${JSON.stringify(formData.projects)}` */}{" "}
                  
                </div>
              </a>

              <br></br>
              <br></br>

              <div align="left" className="position-name-text">
              
                <a
                  href={`${formData.resume}`}
                  align="left"
                  className="position-name-text"
                  target="_blank"
                >

                  Resume

                </a>
              </div>
              

              <br></br>
              
              <div className="paragraph-light cc-position-name" >

              
                {formData.bio}

                
                </div>
                
                
                


              
            </div>
            
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section">

        <a
                  href={`${formData.p0i}`}
                >


          <img
            src={`${formData.p0i}`}
            width="920"
            alt=""
            className="detail-header-image"
          />


                  
</a>
        </div>
        <div className="container">
          <div className="w-layout-grid project-description-grid">
            <div id="w-node-ee63d52fd22b-db957e3d" className="paragraph-light">
              {`${formData.p0ld}`}
            </div>
            <div
              id="w-node-c086a8d10760-db957e3d"
              className="work-position-wrap"
            >
              {/*<div className="position-name-text">CV</div>*/}
              <div className="paragraph-light cc-position-name">
                <strong>{`${formData.p0t}`}</strong>
              </div>
              <div className="paragraph-tiny cc-paragraph-tiny-light">
                {`${formData.p0dim}`}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section">
        <div className="w-background-video w-background-video-atom">
          {/mp4$/.test(formData.p1i) ? (
            <video controls name="media">
            <source src={`${formData.p1i}`}

            type="video/mp4" />
            <source
                src={`${formData.p1i}`}
              />
          </video>
          ) : (
            <a
                  href={`${formData.p1i}`}
                >


          <img
            src={`${formData.p1i}`}
            width="920"
            alt=""
            className="detail-header-image"
          />


                  
</a>
          )}
          {/*<img src={`${formData.p2i}`}alt="" className="detail-header-image" />*/}
          {/*<video controls autoPlay name="media"><source src={'${formData.p2i}'} type="video/mp4" /></video>*/}
          </div>
        </div>
        <div className="container">
          <div className="w-layout-grid project-description-grid">
            <div id="w-node-ee63d52fd22b-db957e3d" className="paragraph-light">
              {`${formData.p1ld}`}
            </div>
            <div
              id="w-node-c086a8d10760-db957e3d"
              className="work-position-wrap"
            >
              {/*<div className="position-name-text">CV</div>*/}

              <div className="paragraph-light cc-position-name">
                <strong>{`${formData.p1t}`}</strong>
              </div>
              <div className="paragraph-tiny cc-paragraph-tiny-light">
                {`${formData.p1dim}`}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="section">
          <div className="w-background-video w-background-video-atom">
          {/mp4$/.test(formData.p2i) ? (
            <video controls name="media">
            <source src={`${formData.p2i}`}

            type="video/mp4" />
            <source
                src={`${formData.p2i}`}
              />
          </video>
          ) : (
            <a
                  href={`${formData.p2i}`}
                >


          <img
            src={`${formData.p2i}`}
            width="920"
            alt=""
            className="detail-header-image"
          />


                  
</a>
          )}
          {/*<img src={`${formData.p2i}`}alt="" className="detail-header-image" />*/}
          {/*<video controls autoPlay name="media"><source src={'${formData.p2i}'} type="video/mp4" /></video>*/}
          </div>
        </div>
      

        <div className="container">
          <div className="w-layout-grid project-description-grid">
            <div id="w-node-ee63d52fd22b-db957e3d" className="paragraph-light">
              {`${formData.p2ld}`}
            </div>
            <div
              id="w-node-c086a8d10760-db957e3d"
              className="work-position-wrap"
            >
              {/*<div className="position-name-text">CV</div>*/}
              <div className="paragraph-light cc-position-name">
                <strong>{`${formData.p2t}`}</strong>
              </div>
              <div className="paragraph-tiny cc-paragraph-tiny-light">
                {`${formData.p2dim}`}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="w-layout-grid project-description-grid2">


          <div
              id="w-node-10dc21ab09d6-db957e3d"
              className="work-position-wrap"
            >
           

            <div className="heading-jumbo">
                Like what you see?
                <br />‍
              </div>


            

            <div
              id="w-node-10dc21ab09cc-db957e3d"
              className="paragraph-contact"
            >
              <strong className="bold-text">Let's get in touch:</strong>
              <br />

              <a href= {`mailto:${formData.email}`}>{formData.email}</a>
              <br />

              {`${formData.social}`.replace("https://", "").replace("/$", "")}{" "}
              <br />
              <br />‍
              
              </div>
            <div
              id="w-node-10dc21ab09d6-db957e3d"
              className="work-position-wrap"
            >
              
           
              
            </div>

            <a href={`${formData.website}`}>
              <div
                data-w-id="4f214ca4-e7a6-8244-5b91-6fa427a56e06"
                className="div-block"
              >
                View Full Portfolio
              </div>
            </a>



            

            </div>
          </div>
        </div>
      </div>
      <div className="footer-wrap">
        <div className="footer-links">
          <a
            href="https://www.instagram.com/perspectivebfa2020/"
            target="_blank"
            className="footer-item"
          >
            Instagram
          </a>
        </div>
      </div>
      {/* [if lte IE 9]><![endif] */}
    </div>
  );
};

export default Designer;

{/*




{<ProfileImage id={formData.uid}></ProfileImage>}

            <div id="w-node-f2c6de040bc4-db957e3d">
              projects[0].title
              
              formDataProjects0.title




              {/^http/.test(formData.website) ? (

                  formData.website
            
          ) : (

            `https://${{formData.website}}`
            
          )}







<video controls name="media">
              <source src={`${formData.p2i}`}

              type="video/mp4" />
              <source
                  src={`${formData.p2i}`}
                />
            </video>




             
            </div>*/}