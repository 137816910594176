import React from "react";


//function Designer(props){

const Acknowledgements = () => {
 


  return (
    <div className="App3">
      <meta charSet="utf-8" />
      <title>Acknowledgements</title>
      <meta content="Project 4" property="og:title" />
      <meta content="Project 4" property="twitter:title" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Webflow" name="generator" />
      <link href="../css/normalize.css" rel="stylesheet" type="text/css" />
      <link href="../css/webflow.css" rel="stylesheet" type="text/css" />
      <link
        href="../css/perspectivebfa.webflow.css"
        rel="stylesheet"
        type="text/css"
      />
      {/* [if lt IE 9]><![endif] */}
      <link
        href="../images/favicon.ico"
        rel="shortcut icon"
        type="image/x-icon"
      />
      <link href="../images/webclip.png" rel="apple-touch-icon" />
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        role="banner"
        className="navigation w-nav"
      >
        <div className="navigation-items">
          <a href="../home" className="logo-link w-nav-brand">
            Perspective BFA 2020
          </a>
          
        </div>
      </div>

     

      

      <div className="section">
        <div className="section">
       
        </div>
        <div className="container">
          <div >
            
            <div
              id="w-node-c086a8d10760-db957e3d"
              className="work-position-wrap"
            >
              

              <div className="paragraph-light cc-position-name">
                <strong><h3>Thank You To Our Sponsors</h3></strong>
              </div>
              <div className="paragraph-light2 paragraph-tiny2">

              <p><span></span>Mike W · <span></span>Nicole V · <span></span>Laura S · <span></span>Claudia P · <span></span>Philip S · <span></span>Cara L · <span></span>Michael · <span></span>Magdelena K · <span/>E D. S · <span></span>Jorge Q · <span></span>Mehrdad B · <span></span>Jean L · Mercedes S · <span></span>Barbara V · <span></span>Diana E · <span></span>Victor V · <span></span>Lauren T · <span></span>Sabrina M · <span></span>Susana W ·  <span></span>Arianna P · <span></span>Daniella M · <span></span>Dickson L · <span></span>Ramsey C · <span></span>John K · <span></span>Camila A. L · <span></span>Natalia O · <span></span>Nikisha P · <span></span>Cindy P · <span></span>Scott J · <span></span>Miranda B · <span></span>Jesus B · <span></span>Lorena F · <span></span>Silvia B · <span></span>Ginette R · <span></span>Margene F · <span></span>Beatriz K · <span></span>Maria P </p>

                <br />
              </div>

              <div id="w-node-ee63d52fd22b-db957e3d" className="paragraph-light">
            <h3>Our graduating class would like to thank everyone that made contributions to our exhibition. Your donations have made this career-defining experience special and truly memorable. We would also like to extend a special thanks to the generous $500 donation from FAU Graphic Design '12 alum, <strong>Daniel MacIntosh</strong> and his company <br/>Echo Studios. </h3>
            <br />
            <br />
            <br />

            <a href ="https://www.echostudiosinc.com"className="logo-link" ><img src="../images/Echo Studios, Inc - Logo.png" width="220px"/></a>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            </div>
          </div>
        </div>
      </div>

      

      
      <div className="footer-wrap">
        <div className="footer-links">
          <a
            href="https://www.instagram.com/perspectivebfa2020/"
            target="_blank"
            className="footer-item"
          >
            Instagram
          </a>
        </div>
      </div>
      {/* [if lte IE 9]><![endif] */}
    </div>
  );
};

export default Acknowledgements;

